import { createWorkEquipmentSchema } from "@/services/references/workEquipment/schemas";

export const parse_work_equipment_to_create = (value) => {
  let data = Object.assign({}, createWorkEquipmentSchema);
  data.status_id = value.status_id;
  data.rate.value = value.rate;
  data.WorkIdentifiedObject.IdentifiedObject.name = value.name;
  data.WorkIdentifiedObject.IdentifiedObject.description = value.description;
  data.code = value.code;
  return data;
};

export const parse_work_equipment_to_update = (value) => {
  let data = Object.assign({}, value);
  data.status_id = value.status.id;
  return data;
};
