var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.title)?_c('h3',{staticClass:"table-title"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('div',{staticClass:"overflow-auto table-wrapper table-auto-height"},[_c('table',{ref:"table",staticClass:"custom-table table-hover"},[_c('thead',[_c('tr',[_vm._t("columns",function(){return _vm._l((_vm.columns),function(column){return _c('th',{key:column.name},[_c('div',{staticClass:"d-inline-flex align-items-center"},[_c('span',[_vm._v(_vm._s(column.name))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(Object.keys(_vm.filters).indexOf(column.path) > -1),expression:"Object.keys(filters).indexOf(column.path) > -1"}],staticClass:"input-group"},[_c('input',{staticClass:"ms-3 form-control search",attrs:{"type":"text","name":"search","placeholder":"Поиск..."},on:{"input":function (e) { return _vm.filterHandle(e.target.value, column.path); }}})])])])})}),(_vm.actions)?_c('th',[_vm._v("Действия")]):_vm._e()],2)]),_c('tbody',[_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.length === 0),expression:"data.length === 0"}]},[_c('td',{staticClass:"text-center",attrs:{"colspan":"100%"}},[_vm._v("Данных нет")])]),_vm._l((_vm.paginatedData),function(item,index){return [_c('tr',{key:index + 'item',on:{"click":function($event){return _vm.$emit('rowClick', item)}}},[_vm._t("default",function(){return _vm._l((_vm.columns),function(column){return _c('td',{key:index + column.name},[(
                    column.path ===
                    'WorkIdentifiedObject.IdentifiedObject.name'
                  )?[_c('p',{staticClass:"mb-1",domProps:{"textContent":_vm._s(
                      _vm.lodash.get(
                        item,
                        'WorkIdentifiedObject.IdentifiedObject.name'
                      )
                    )}},[_vm._v(" Название ")]),_c('p',{staticClass:"mb-0"},[_c('small',{domProps:{"textContent":_vm._s(
                        _vm.lodash.get(
                          item,
                          'WorkIdentifiedObject.IdentifiedObject.description'
                        )
                      )}},[_vm._v("Описание")])])]:[_vm._v(" "+_vm._s(_vm.lodash.get(item, column.path, " - "))+" ")]],2)})},{"row":item,"index":(_vm.pageNumber - 1) * _vm.pageSize + 1 + index}),(_vm.actions)?_c('td',[_vm._t("actions",function(){return [_vm._v(" Нет доступных действий ")]},{"row":item,"index":index})],2):_vm._e()],2)]})],2),_c('caption',{directives:[{name:"show",rawName:"v-show",value:(_vm.sortedItems.length > _vm.pageSize),expression:"sortedItems.length > pageSize"}],staticClass:"text-end"},[_c('div',{staticClass:"d-flex flex-row-reverse"},[_c('ul',{staticClass:"pagination mb-0 me-3"},[_c('li',{staticClass:"page-item p-0",class:{ disabled: _vm.pageNumber === 1 }},[_c('a',{staticClass:"page-link",staticStyle:{"cursor":"pointer"},on:{"click":_vm.previousPage}},[_vm._v("Назад")])]),_vm._l((_vm.paginatedPages.pages),function(page){return _c('li',{key:page,staticClass:"page-item p-0",class:{ active: page === _vm.pageNumber }},[_c('a',{staticClass:"page-link",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.setPage(page)}}},[_vm._v(_vm._s(page))])])}),_c('li',{staticClass:"page-item p-0",class:{ disabled: _vm.pageNumber === _vm.paginatedPages.totalPages }},[_c('a',{staticClass:"page-link",staticStyle:{"cursor":"pointer"},on:{"click":_vm.nextPage}},[_vm._v("Вперед")])])],2)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }