import axios from "@/plugins/axios";
import {
  parse_work_equipment_to_create,
  parse_work_equipment_to_update,
} from "@/services/references/workEquipment/parsers";

/**
 * Получение списка EnproCUWorkEquipmentCode
 * @return {Promise<WorkEquipmentObject[]>}
 */
export const API_work_equipment_list = () => {
  return axios("/enproCUWorkEquipmentCode?perPage=5000", {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Получение EnproCUWorkEquipmentCode по ID
 * @param {number} workEquipmentId
 * @return {Promise<WorkEquipmentObject>}
 */
export const API_work_equipment_view = (workEquipmentId) => {
  return axios(`/enproCUWorkEquipmentCode/${workEquipmentId}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Получение EnproCUWorkEquipmentCode по ID
 * @param {number} workEquipmentId
 * @param {Object} value
 * @return {Promise<WorkEquipmentObject>}
 */
export const API_work_equipment_update = (workEquipmentId, value) => {
  return axios(`/enproCUWorkEquipmentCode/${workEquipmentId}`, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
    },
    data: parse_work_equipment_to_update(value),
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Создание EnproCUWorkEquipmentCode
 * @param {Object} value
 * @return {Promise<WorkEquipmentObject>}
 */
export const API_work_equipment_create = (value) => {
  return axios("/enproCUWorkEquipmentCode/", {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: parse_work_equipment_to_create(value),
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Удаление EnproCUWorkEquipmentCode по ID
 * @param {number} workEquipmentId
 * @return {Promise<boolean>}
 */
export const API_work_equipment_delete = (workEquipmentId) => {
  return axios(`/enproCUWorkEquipmentCode/${workEquipmentId}`, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
    },
  })
    .then(() => {
      return true;
    })
    .catch((error) => {
      throw error;
    });
};
