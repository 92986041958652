<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2>Часовые стоимости эксплуатации оборудования</h2>
      <div>
        <UIButton
          class="add-btn"
          text="Добавить"
          leftIcon="bi-plus"
          @click="addWorkData"
        />
      </div>
    </div>
    <TableSlot v-bind="workEquipments" @rowClick="showWorkDetails" />
  </div>
</template>

<script>
import { API_work_equipment_list } from "@/services/references/workEquipment";
import TableSlot from "@/components/TableSlot";
import UIButton from "@/components/UI/Button";

export default {
  name: "enproCUWorkEquipmentCodeList",
  components: { TableSlot, UIButton },
  data() {
    return {
      workEquipments: {
        columns: [
          {
            name: "Наименование",
            path: "WorkIdentifiedObject.IdentifiedObject.name",
          },
          {
            name: "Код",
            path: "code",
          },
          {
            name: "Тариф",
            path: "rate.value",
          },
          {
            name: "Статус",
            path: "status.value",
          },
        ],
        data: [],
        filters: {
          "WorkIdentifiedObject.IdentifiedObject.name": "",
          code: "",
        },
      },
    };
  },
  mounted() {
    API_work_equipment_list().then((res) => {
      this.workEquipments.data = res;
    });
  },
  methods: {
    showWorkDetails(item) {
      this.$router.push({
        path: `/references/work-equipment/${item.id}`,
      });
    },
    addWorkData() {
      this.$router.push({
        path: `/references/work-equipment/add`,
      });
    },
  },
};
</script>

<style scoped></style>
