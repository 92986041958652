<template>
  <div>
    <h3 v-if="title" class="table-title">{{ title }}</h3>
    <div class="overflow-auto table-wrapper table-auto-height">
      <table ref="table" class="custom-table table-hover">
        <thead>
          <tr>
            <slot name="columns">
              <th v-for="column in columns" :key="column.name">
                <div class="d-inline-flex align-items-center">
                  <span>{{ column.name }}</span>
                  <div
                    v-show="Object.keys(filters).indexOf(column.path) > -1"
                    class="input-group"
                  >
                    <input
                      type="text"
                      name="search"
                      placeholder="Поиск..."
                      @input="(e) => filterHandle(e.target.value, column.path)"
                      class="ms-3 form-control search"
                    />
                  </div>
                </div>
              </th>
            </slot>
            <th v-if="actions">Действия</th>
          </tr>
        </thead>
        <tbody>
          <tr v-show="data.length === 0">
            <td colspan="100%" class="text-center">Данных нет</td>
          </tr>
          <template v-for="(item, index) in paginatedData">
            <tr :key="index + 'item'" @click="$emit('rowClick', item)">
              <slot
                :row="item"
                :index="(pageNumber - 1) * pageSize + 1 + index"
              >
                <td v-for="column in columns" :key="index + column.name">
                  <template
                    v-if="
                      column.path ===
                      'WorkIdentifiedObject.IdentifiedObject.name'
                    "
                  >
                    <p
                      class="mb-1"
                      v-text="
                        lodash.get(
                          item,
                          'WorkIdentifiedObject.IdentifiedObject.name'
                        )
                      "
                    >
                      Название
                    </p>
                    <p class="mb-0">
                      <small
                        v-text="
                          lodash.get(
                            item,
                            'WorkIdentifiedObject.IdentifiedObject.description'
                          )
                        "
                        >Описание</small
                      >
                    </p>
                  </template>
                  <template v-else>
                    {{ lodash.get(item, column.path, " - ") }}
                  </template>
                </td>
              </slot>
              <td v-if="actions">
                <slot name="actions" :row="item" :index="index">
                  Нет доступных действий
                </slot>
              </td>
            </tr>
          </template>
        </tbody>
        <caption class="text-end" v-show="sortedItems.length > pageSize">
          <div class="d-flex flex-row-reverse">
            <ul class="pagination mb-0 me-3">
              <li class="page-item p-0" :class="{ disabled: pageNumber === 1 }">
                <a
                  class="page-link"
                  style="cursor: pointer"
                  @click="previousPage"
                  >Назад</a
                >
              </li>

              <li
                class="page-item p-0"
                v-for="page in paginatedPages.pages"
                :class="{ active: page === pageNumber }"
                :key="page"
              >
                <a
                  class="page-link"
                  style="cursor: pointer"
                  @click="setPage(page)"
                  >{{ page }}</a
                >
              </li>

              <li
                class="page-item p-0"
                :class="{ disabled: pageNumber === paginatedPages.totalPages }"
              >
                <a class="page-link" style="cursor: pointer" @click="nextPage"
                  >Вперед</a
                >
              </li>
            </ul>
          </div>
        </caption>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableSlot",
  props: {
    title: String,
    columns: Array,
    data: {
      type: Array,
      default: () => [],
    },
    actions: Boolean,
    filters: {
      type: Object,
      default() {
        return {
          "WorkIdentifiedObject.IdentifiedObject.name": "",
          "IdentifiedObject.name": "",
        };
      },
    }, //из-за особенностей vue2 объект с полями должен быть определен до момента первой отрисовки
  },
  data() {
    return {
      sorting: { col: undefined, isDesc: false },
      pageSize: 20,
      pageNumber: 1,
    };
  },
  watch: {
    paginatedPages: function (newVal) {
      if (newVal.totalPages < this.pageNumber) {
        this.pageNumber = 1;
      }
    },
  },
  computed: {
    paginatedData() {
      return this.filteredItems.slice(
        (this.pageNumber - 1) * this.pageSize,
        this.pageNumber * this.pageSize
      );
    },
    sortedItems() {
      if (this.sorting.col) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return this.data.sort((a, b) => {
          let _a = this.lodash.get(a, this.sorting.col);
          let _b = this.lodash.get(b, this.sorting.col);
          if (this.sorting.isDesc) _b = [_a, (_a = _b)][0]; // Swap variables
          if (_a < _b) return -1;
          if (_a > _b) return 1;
          return 0;
        });
      } else return this.data;
    },
    filteredItems() {
      let items = this.sortedItems;
      Object.keys(this.filters).forEach((path) => {
        items = items.filter(
          (item) =>
            (this.lodash.get(item, path) || "")
              .toLowerCase()
              .indexOf(this.filters[path].toLowerCase()) !== -1
        );
      });
      return items;
    },
    paginatedPages() {
      let maxPages = 5;
      let totalPages = Math.ceil(this.filteredItems.length / this.pageSize);

      let startPage, endPage;
      if (totalPages <= maxPages) {
        startPage = 1;
        endPage = totalPages;
      } else {
        let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (this.pageNumber <= maxPagesBeforeCurrentPage) {
          startPage = 1;
          endPage = maxPages;
        } else if (this.pageNumber + maxPagesAfterCurrentPage >= totalPages) {
          startPage = totalPages - maxPages + 1;
          endPage = totalPages;
        } else {
          startPage = this.pageNumber - maxPagesBeforeCurrentPage;
          endPage = this.pageNumber + maxPagesAfterCurrentPage;
        }
      }
      let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
        (i) => startPage + i
      );
      return {
        totalPages: totalPages,
        pages: pages,
      };
    },
  },
  methods: {
    filterHandle(value, path) {
      this.filters[path] = value;
    },
    nextPage() {
      this.pageNumber += 1;
    },
    previousPage() {
      this.pageNumber -= 1;
    },
    setPage(value) {
      this.pageNumber = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-title {
  margin: 0;
  padding: 6px 30px;
  background-color: $main-3;
}
.custom-table {
  margin-bottom: 0;
  color: white;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;

  th,
  td {
    border: none;
    cursor: default;
    &:first-child {
      .checkbox {
        padding-left: 20px;
        margin-bottom: 0;
      }
    }
  }

  thead {
    th {
      background-color: $main-1;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      padding: 20px 20px;
      color: white;
      box-sizing: border-box;
      border-bottom: 0;
      vertical-align: middle;

      &:not(:first-child) {
        border-left: 1px solid #18191d;
      }
      .search {
        border: solid;
        border-width: 1px !important;
        border-color: $highlight;
        color: $highlight;

        &:hover {
          border-color: $highlight-hover;
          color: $highlight-hover;
        }
      }
    }

    .no-wrap .form-control {
      margin-left: 10px;
    }
  }

  tbody {
    tr {
      background-color: $main-3;
      //&:not(:first-child):hover {
      //  background-color: $main-2;
      //}
    }
    td {
      border-top: 1px solid #292b2f;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      padding: 20px 20px;
      color: #fff;
      box-sizing: border-box;
      vertical-align: middle;

      &:not(:first-child) {
        border-left: 1px solid #292b2f;
        cursor: pointer;
      }
    }
  }
}
.footer-bar {
  background-color: $main-3;
  //display: flex;
  padding: 10px 20px;
  position: relative;
}
</style>
